<template>
  <div class="EnterpriseInternalRecruitment">
    <el-page-header @back="backPrivious" content="公司简介"></el-page-header>
    <!-- <div class="enterpriseInfo-info f-c">
      <el-image :src="enterpriseInfo.eimage1"></el-image>
      <div>{{ enterpriseInfo.enterpriseName }}</div>
    </div>
    <div class="enterpriseInfo-info f-cl">
      公司地址：<el-input :disabled="true" v-model="enterpriseInfo.address"></el-input>
    </div>
    <div class="enterpriseInfo-info f-cl">
      联系电话：<el-input :disabled="true" v-model="enterpriseInfo.enterpriseTelephone"></el-input>
    </div>
    <div class="enterpriseInfo-info f-cl">
      公司介绍：
      <TextArea :modelValue="enterpriseInfo.introduction" :disabledValue="true"></TextArea>
    </div> -->
    <!-- <el-divider content-position="left">在招职位</el-divider>
    <div class="list">
      <PositionItem v-for="(item, index) of list" :Jobitem="item" :key="index"></PositionItem>
    </div>
    <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout" @pagination="fetchData" /> -->
    <div class="heard f-c">
      <el-image :src="enterpriseInfo.eimage1" :preview-src-list="[enterpriseInfo.eimage1]" alt=""></el-image>
      <span>{{ enterpriseInfo.enterpriseName }}</span>
    </div>
    <div class="main">
      <div class="title flex">
        <div :class="select == 1 ? 'select' :'Noselect' " @click="select = 1" style="margin-right: 50px;">公司简介</div>
        <div :class="select == 2 ? 'select' :'Noselect' " @click="select = 2">在招职位</div>
      </div>
      <div class="content f-w-b" v-show="select == 1">
        <div class="item">
          <div class="label">联系人</div>
          <div class="value">{{ enterpriseInfo.contact }}</div>
        </div>
        <div class="item">
          <div class="label">所在城市</div>
          <div class="value">{{ enterpriseInfo.city }}</div>
        </div>
        <div class="item">
          <div class="label">企业电话</div>
          <div class="value">{{ enterpriseInfo.enterpriseTelephone }}</div>
        </div>
        <div class="item">
          <div class="label">详细地址</div>
          <div class="value">{{ enterpriseInfo.address }}</div>
        </div>
        <div class="item">
          <div class="label">联系电话</div>
          <div class="value">{{ enterpriseInfo.contactTelephone }}</div>
        </div>
        <div class="intro">
          <div class="label">企业简介</div>
          <div class="introduction">
            {{ enterpriseInfo.introduction }}
          </div>

        </div>
      </div>
      <div class="job f-w-b" v-show="select == 2">
        <div class="jobItem f-c-b" v-for="(item, index) of list" :key="index" @click="toJobdetail(item.postId)">
          <div>
            <div class="jobname">{{ item.position }}</div>
            <div class="time">发布时间：{{ item.createDate }}</div>
          </div>
          <div class="salary">
            {{ item.salary }}
          </div>
        </div>
        <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
          @pagination="fetchData" />
      </div>
    </div>

  </div>
</template>

<script>
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
export default {
  name: 'EnterpriseInternalRecruitment',
  mixins: [PaginationRetention],
  components: {
    // PositionItem: () => import('./components/PositionItem.vue')
  },
  data() {
    return {
      list: [],
      enterpriseInfo: {},
      select: 1,
    }
  },
  created() {
    let { city, contact, enterpriseTelephone, introduction, eimage1, enterpriseName, contactTelephone } = this.$getStorage('EnterpriseInternalRecruitmentInfo').enterprise;
    console.log(this.$getStorage('EnterpriseInternalRecruitmentInfo').enterprise)
    this.enterpriseInfo = {
      address: city + contact,
      city,
      enterpriseTelephone,
      introduction,
      eimage1,
      enterpriseName,
      contact,
      contactTelephone,
    }
  },
  methods: {
    getDate() {
      this.forumRecruitArticleList(this.paging, this.pagingSize)
    },
    //  企业内部职位招聘帖子列表接口
    forumRecruitArticleList(page = this.page, size = this.size) {
      let that = this, { enterpriseId } = that.$getStorage('EnterpriseInternalRecruitmentInfo')
      that.$http.forumRecruitArticleList({
        postUserId: parseInt(that.$store.state.userId),
        token: that.$getStorage('token'),
        page,
        size,
        enterpriseId
      }).then(res => {
        if (res.code == 200) {
          res.data.records.forEach(item => {
            item.expirationDate1 = item.expirationDate ? item.expirationDate.split(' ')[0] : ''
          })
          that.list = res.data.records
          that.currentPage = res.data.current;//当前页数
          that.total = res.data.total
        }
      })
    },
    toJobdetail(data){
      this.$router.push({
          path: '/TalentRecruitmentDetail',
          query: {
            key: this.$UrlEncode.encode(JSON.stringify({
              type: 'detail',
              id: data
            }))
          }
        })
    }
  },
}

</script>
<style lang='less' scoped>
.EnterpriseInternalRecruitment {
  .el-image {
    width: 90px;
    height: 80px;
    border-radius: 10px;
    margin-right: 20px;
  }

  .enterpriseInfo-info {
    margin-bottom: 20px;

    .el-input,
    .el-textarea {
      margin-top: 10px;
    }

  }

  /deep/ .el-divider--horizontal {
    margin: 30px 0;
  }

  /deep/ .el-divider__text {
    font-size: 20px;
    font-weight: bold;
  }

  .heard {
    margin-top: 20px;
    margin-bottom: 50px;
    padding: 0 20px;
    height: 160px;
    background: url('../../../assets/bg_supplier.png') no-repeat;
    border-radius: 5px;

    .el-image {
      border-radius: 5px;
      width: 100px;
      height: 100px;
      margin-right: 20px;
    }

    span {
      font-size: 36px;
      color: #FFFFFF;
    }
  }

  .main {
    .title {
      width: 250px;

      .select {
        color: #0363FA;
        font-size: 24px;
        font-family: HarmonyOS Sans SC;
        font-weight: 500;
        border-bottom: 2px solid #0363FA;
        padding-bottom: 26px;
        cursor: pointer;
      }

      .Noselect {
        font-size: 24px;
        font-family: HarmonyOS Sans SC;
        font-weight: 500;
        cursor: pointer;
      }
    }

    .content {
      border-top: 1px solid #F7F7F7;
      padding-top: 26px;

      .item {
        width: 560px;
        height: 60px;
        background: #F5F8F9;
        padding: 14px 20px;
        margin-bottom: 15px;
        border-radius: 5px;

        .label {
          font-size: 14px;
          font-family: HarmonyOS Sans SC;
          font-weight: 400;
          color: #999999;
          line-height: 30px;
        }

        .value {
          font-size: 16px;
          font-family: HarmonyOS Sans SC;
          font-weight: 400;
          color: #333333;
          line-height: 30px;
        }
      }

      .intro {
        .label {
          font-size: 14px;
          font-family: HarmonyOS Sans SC;
          font-weight: 400;
          color: #999999;
          line-height: 30px;
        }

        .introduction {
          font-size: 16px;
          font-family: HarmonyOS Sans SC;
          font-weight: 400;
          color: #333333;
          line-height: 32px;
        }
      }
    }
  }

  .job {
    margin-top: 20px;
    .jobItem {
      width: 550px;
      height: 70px;
      padding: 40px;
      border-radius: 20px;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05), -1px 1px 1px rgba(0, 0, 0, 0.05), 1px -1px 1px rgba(0, 0, 0, 0.05), -1px -1px 1px rgba(0, 0, 0, 0.05) !important;
      margin-bottom: 20px;
      cursor: pointer;

      .jobname {
        font-size: 24px;
        font-family: HarmonyOS Sans SC;
        font-weight: 400;
        color: #333333;
        margin-bottom: 30px;
      }

      .time {
        font-size: 16px;
        font-family: HarmonyOS Sans SC;
        font-weight: 400;
        color: #999999;
      }

      .salary {
        font-size: 24px;
        font-family: HarmonyOS Sans SC;
        font-weight: 400;
        color: #FFA300;
      }
    }
  }
}
</style>
